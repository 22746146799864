import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { GlobalService } from "../services/global.service";

@Injectable({ providedIn: 'root' })
export class RoleGuardService {
    constructor(
        private router: Router,
        private globalService: GlobalService
    ){}

    public canActivate(route: ActivatedRouteSnapshot, url: string){
        const requiredRoles = route.data['requiredRoles'];

        if (this.isAuthorized(requiredRoles)) {
            return true;
        }
        this.router.navigate(['/chatbot']);
        return false;
    }

    private isAuthorized(requiredRoles: string[]): boolean {
        return requiredRoles.includes(this.globalService.currentUser.userData.role.roleName);
    }

}